<template>
    <div>
        <b-card no-body>
            <b-table ref="refAgencyRevenueListTable" class="position-relative table-white-space mb-0 max-height-table"
                sticky-header head-variant="light" :items="commissionSettingResult" responsive :fields="tableColumns"
                primary-key="index" show-empty empty-text="No matching records found">
                <template #cell(from)="data">
                    {{ data.item.from ? numberFormat(data.item.from) : "" }}
                </template>
                <template #cell(to)="data">
                    {{ data.item.to ? numberFormat(data.item.to) : "" }}
                </template>
                <template #cell(action)="data">
                    <b-button v-b-tooltip.hover.v-info :title="$t('Edit')" v-if="$can('update', 'agencyrevenuesetting')"
                        variant="primary" class="btn-icon btn-sm mr-50" @click="handleUpdateAgencyRevenue(data.item)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalSportTeam" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <update-agency-revenue-modal :agency-revenue-detail.sync="agencyRevenueDetail"
            :agencyRevenueDetail="agencyRevenueDetail" @refetch-data="refetchData" />
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg, BSpinner, VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import i18n from "@/libs/i18n";
import VueExportExcel from "@/views/extensions/export/VueExportExcel";

export default {
    components: {
        UpdateAgencyRevenueModal: () => import("./UpdateAgencyRevenueModal.vue"),
        flatPickr,
        AddSportsTeamModal,
        UpdateSportTeamModal,
        VueExportExcel,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
        BSpinner,
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    props: {
        userData: {
            type: Object,
        },
    },
    data() {
        return {
            sportTeamDetail: null,
            exportFiletitle: `AgencyCommission-${new Date()
                .toJSON()
                .replace(":", "-")
                .substring(0, 16)}`,
            exportFileSheetName: "AgencyCommission",
            agencyRevenueDetail: null
        };
    },
    methods: {
        handleUpdateAgencyRevenue(datas) {
            this.agencyRevenueDetail = datas;
            this.$bvModal.show("modal-update-agency-revenue");
        },
    },
    setup(props) {
        if (!store.hasModule("marketing"))
            store.registerModule("marketing", marketingStoreModule);
        onUnmounted(() => {
            if (store.hasModule("marketing")) store.unregisterModule("marketing");
        });

        const getListYear = () => {
            const currentYear = new Date().getFullYear();
            const startYear = 2023;
            const yearList = [];
            for (let i = startYear; i <= currentYear; i++) {
                yearList.push(i);
            }
            return yearList;
        };

        const sportTypeIdFilter = ref(null);
        const perPage = ref(25);
        const totalSportTeam = ref(0);
        const currentPage = ref(1);
        const isProductId = ref(false);

        const refAgencyRevenueListTable = ref(null);
        const tableColumns = [
            { key: "index", label: i18n.t("#"), sortable: false },
            { key: "from", label: i18n.t("From"), sortable: true },
            { key: "to", label: i18n.t("To"), sortable: true },
            { key: "min_user", label: i18n.t("Min User"), sortable: true },
            { key: "discount", label: i18n.t("Discount (%)"), sortable: true },
            { key: "updated_by", label: i18n.t("Updated by"), sortable: true },
            { key: "action", label: i18n.t("Actions") },
        ];

        const commissionSettingResult = ref([]);

        const fetchAgencyCommissionSetting = () => {
            store
                .dispatch("marketing/fetchAgencyCommissionSetting")
                .then((response) => {
                    commissionSettingResult.value = response.data.data.map((item, index) => {
                        return {
                            ...item,
                            index: index + 1
                        }
                    });
                });
        }

        fetchAgencyCommissionSetting();

        const refetchData = () => {
            fetchAgencyCommissionSetting();
            refAgencyRevenueListTable.value.refresh();
        };

        const dataMeta = computed(() => {
            return {
                from: 1,
                to: commissionSettingResult.value.length,
                of: commissionSettingResult.value.length,
            };
        });

        return {
            isProductId,
            tableColumns,
            perPage,
            currentPage,
            totalSportTeam,
            dataMeta,
            refAgencyRevenueListTable,
            refetchData,
            sportTypeIdFilter,
            numberFormat,
            formatDateTime,
            baseUrl,
            getListYear,
            commissionSettingResult,
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  